@font-face {
  font-family: 'EOB';
  src: url('../fonts/earthorbiterbold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.matrix-container {
  display: block;
}

#matrix-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 0;
  font-size: 122px;
}

.matrix-canvas {
  font: 12px 'Space Mono', monospace;
  color: #0F0;
}

.mainline {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent;
  color: #ffffff;
  font-size: 2em;
  z-index: 1;
  position: relative;
  animation: delay 4s, fadeIn 9s ease-in-out;
  animation-fill-mode: forwards;
}
.mainline > h1 {
  font-family: 'EOB', 'Open Sans', sans-serif;
  font-size: 2em;
  color: #ffffff;
  font-weight: 600;
  white-space: nowrap;
}

.avatar {
  display: inline-block;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 20px;
  animation: fadeIn 4.32s ease-in-out;
}
.avatar img {
  display: block;
  margin: auto 5px;
  padding: 10px;
  height: 240px;
  max-width: 240px;
  border-radius: 50%;
}

.infobar {
  display: inline-block;
  z-index: 1;
  position: absolute;
  top: 10px;
  right: 20px;
  animation: fadeIn 4.32s ease-in-out;
}
.infobar .infobar-item {
  display: inline-block;
  margin: auto 5px;
  padding: 10px;
  border-radius: 50%;
  background: #ffffff;
  transition: background-color 0.3s ease;
}
.infobar .infobar-item:hover {
  background-color: rgba(65, 105, 225, 0.7);
}
.infobar .infobar-item img {
  display: block;
  height: 32px;
  max-width: 32px;
}

.blinking-cursor {
  user-select: none;
  animation: blink 1s steps(2, start) infinite;
}

/* Animation */
@keyframes delay {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .mainline {
    font-size: 1.5em; /* Adjust font size for mobile */
  }
  .mainline > h1 {
    font-size: 1.5em; /* Adjust font size for mobile */
    white-space: normal; /* Allow text wrapping on mobile */
    text-align: center; /* Center-align text on mobile */
  }

  .avatar img {
    display: block;
    margin: auto 5px;
    padding: 10px;
    height: 150px;
    max-width: 150px;
    border-radius: 50%;
  }
}